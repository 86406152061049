import { FC, useState } from "react";
import Lottie from "lottie-react";

import { bodyScanScreenUI, font } from "../../configs";
import { capitalizeFirstLetter } from "../../utils";
import { GENDERS } from "../../constants/modal";

import "./index.css";

interface IPropsBellyChoice {
  image: string;
  onClick: () => void;
  text: string;
  isSelected?: boolean;
  type?: string;
  gender?: string;
}

type BellyChoiceType = "unfocused" | "focused";

const BellyChoice: FC<IPropsBellyChoice> = ({
  image,
  onClick,
  text,
  isSelected,
  type,
  gender,
}) => {
  const [loading, setLoading] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get("lang")?.split("-")[0].toLowerCase();

  const torsoClass = loading
    ? "torso_choice__image_loading"
    : "torso_choice__image";

  const cuissesClass = loading
    ? "cuisses_choice__image_loading"
    : "cuisses_choice__image";

  const getSelectorStyles = (selectorType: BellyChoiceType) => {
    const ctaStyles = bodyScanScreenUI.morphoCTA[selectorType]!;

    const selectorSizeStyles = {
      backgroundColor:
        ctaStyles.backgroundColor as React.CSSProperties["backgroundColor"],
      borderRadius:
        ctaStyles.borderRadius as React.CSSProperties["borderRadius"],
      border: `${
        ctaStyles.borderWidth as React.CSSProperties["borderWidth"]
      } solid ${ctaStyles.borderColor as React.CSSProperties["borderColor"]}`,
      fontWeight: ctaStyles.fontWeight as React.CSSProperties["fontWeight"],
      color: ctaStyles.fontColor as React.CSSProperties["color"],
      fontSize:
        (lang === "de" || lang === "en") &&
        type === "torso" &&
        gender === GENDERS.F
          ? `calc(${ctaStyles.fontSize} - 2px)`
          : (ctaStyles.fontSize as React.CSSProperties["fontSize"]),
      textAlign: ctaStyles.textAlign as React.CSSProperties["textAlign"],
      textTransform:
        ctaStyles.textTransform as React.CSSProperties["textTransform"],
    };

    return selectorSizeStyles;
  };

  const selectorStyles = isSelected
    ? getSelectorStyles("focused")
    : getSelectorStyles("unfocused");

  return (
    <button
      className={isSelected ? "belly_choice_selected" : "belly_choice"}
      onClick={onClick}
      style={{
        ...selectorStyles,
        fontFamily: `${font}, sans-serif`,
        textTransform:
          selectorStyles?.textTransform === "capitalize"
            ? "none"
            : selectorStyles?.textTransform,
      }}
    >
      {loading === true ? (
        <Lottie
          className="loading"
          animationData={require("../../assets/animations/loading_image.json")}
        />
      ) : null}
      <img
        className={
          type === "torso"
            ? torsoClass
            : type === "cuisses"
            ? cuissesClass
            : loading
            ? "belly_choice__image_loading"
            : "belly_choice__image"
        }
        alt="torso"
        src={image}
        onLoad={(v) => {
          setLoading(false);
        }}
      />
      <span
        style={{
          ...selectorStyles,
          border: "none",
          fontFamily: `${font}, sans-serif`,
          textTransform:
            selectorStyles?.textTransform === "capitalize"
              ? "none"
              : selectorStyles?.textTransform,
        }}
      >
        {selectorStyles?.textTransform === "capitalize"
          ? capitalizeFirstLetter(text)
          : text}
      </span>
    </button>
  );
};

export default BellyChoice;
